// Libs
import React, { Component } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router';
import moment from 'moment';
 
// Services
import API from 'API';

// Components
import Layout from 'components/layout/Layout';
import Loader from 'components/common/Loader';
import Login from 'components/Login';

// Components - pages
import ListCustomers from 'components/customer/ListCustomers';
import DaySheet from 'components/appt/DaySheet';
import ListAppts from 'components/appt/ListAppts';
import ListRebooks from 'components/rebook/ListRebooks';
import CustomerCommListPage from 'components/outgoing/CustomerCommListPage';
import Setup from 'components/setup/Setup';
import ListReports from 'components/reports/ListReports';
import Report from 'components/reports/Report';
import Banking from 'components/appt/Banking';
import ListAccounts from 'components/head-office/ListAccounts';

window.moment = moment;

//-------------------------------------------------------------------------------------------------------------------

class App extends Component {

    static VERSION = 'd1.22';

    constructor(props) {
        super(props);

        this.setTitle = this.setTitle.bind(this);
        this.checkLogin = this.checkLogin.bind(this);

        this.state = {
            isLoading: true,
            loginInfo: null
        };
    }

    async componentDidMount() {
        window.updateSyncStatus = () => null;
        this.checkLogin();
    }

    async checkLogin() {
        // Get account code from URL
        const path = window.location.pathname;
        let accountCode = path.length > 0 ? path.split('/')[1] : '';
        if (accountCode) {
            API.extraHeaders['account-code'] = accountCode;
        }

        // Determine if logged in
        let loginInfo = null;
        try {
            loginInfo = await API.call('account/get-login-info');
        } catch (e) {
            // Ignore error - loginInfo will be null
        }

        if (loginInfo) {
            // Get default account code if there isn't one specified
            if (!accountCode) {
                if (!loginInfo.account) {
                    await API.call('account/log-out');
                    alert('Sorry, you don\'t appear to belong to any accounts. Please contact support.');
                    window.location.reload();
                }
                accountCode = loginInfo.account.accountCode;
                window.location = `/${accountCode}`;
            } else {
                this.setTitle();
            }
        }

        // Update UI
        this.setState({
            loginInfo,
            accountCode,
            isLoading: false
        });
    }

    setTitle(page) {
        const { loginInfo } = this.state;
        const hasMultiple = (loginInfo && loginInfo.accounts.length > 1);
        const accountName = (loginInfo && loginInfo.account ? loginInfo.account.accountName : null);
        let parts = [];
        if (hasMultiple) {
            parts.push(accountName);
            parts.push(page);
        } else {
            parts.push(page);
            parts.push(accountName);
        }
        parts = parts.filter(p => !!p);
        if (parts.length == 0) {
            parts.push('ProSweep3');
        }
        document.title = parts.join(' | ');
    }

    //-------------------------------------------------------------------------------------------------------------------

    render() {
        const {
            isLoading,
            loginInfo
        } = this.state;
        const rootNode = document.querySelector('#root');

        // Loading login status
        if (isLoading) {
            return (<Loader />);
        }

        // Not logged in yet
        if (!loginInfo || !loginInfo.user) {
            rootNode.classList.add('not-logged-in');
            return this.renderNotLoggedIn();
        }

        // Logged in
        rootNode.classList.remove('not-logged-in');
        return this.renderLoggedIn();
    }

    renderNotLoggedIn() {
        return (
            <Login
                onLogIn={this.checkLogin}
            />
        );
    }

    renderLoggedIn() {
        const {
            loginInfo
        } = this.state;
        
        const getChildProps = (props) => ({
            ...props.match.params,
            history: props.history,
            loginInfo,
            fns: {
                setTitle: this.setTitle,
                checkLogin: this.checkLogin
            }
        });

        return (
            <Layout loginInfo={loginInfo}>
                <Switch>
                    {/* Customers */}
                    <Route path="/customers/:type?" render={(props) =>
                        <ListCustomers {...getChildProps(props)} />}
                    />

                    {/* Day Sheet / Appts */}
                    <Route path="/day-sheet" render={(props) =>
                        <DaySheet {...getChildProps(props)} />}
                    />
                    <Route path="/appts/:page?" render={(props) =>
                        <ListAppts {...getChildProps(props)} />}
                    />

                    {/* Rebooks */}
                    <Route path="/rebooks/:month?" render={(props) =>
                        <ListRebooks {...getChildProps(props)} />}
                    />

                    {/* Customer comms */}
                    <Route path="/customer-comms" render={(props) =>
                        <CustomerCommListPage {...getChildProps(props)} />}
                    />

                    {/* Banking */}
                    <Route path="/banking" render={(props) =>
                        <Banking {...getChildProps(props)} />}
                    />

                    {/* Reports */}
                    <Route path="/reports" render={(props) =>
                        <ListReports {...getChildProps(props)} />}
                    />
                    <Route path="/report/:id?" render={(props) =>
                        <Report {...getChildProps(props)} />}
                    />
                    
                    {/* Setup */}
                    <Route path="/setup/:page?" render={(props) =>
                        <Setup {...getChildProps(props)} />}
                    />

                    {/* Head office */}
                    <Route path="/head-office/accounts" render={(props) =>
                        <ListAccounts {...getChildProps(props)} />}
                    />

                    <Redirect to="/day-sheet" />
                </Switch>

            </Layout>
        );
    }
}

export default withRouter(App);
